@media only screen and (min-width: 125em) {
  html {
    font-size: 13.2px;
  }
}
@media only screen and (max-width: 105em) {
  html {
    font-size: 9.28px;
  }
}
@media only screen and (max-width: 90em) {
  html {
    font-size: 8.8px;
  }
}
@media only screen and (max-width: 85.375em) {
  html {
    font-size: 8.8px;
  }
}
@media only screen and (max-width: 80em) {
  html {
    font-size: 8.8px;
  }
}
@media only screen and (max-width: 70em) {
  input[type="text"],
  input[type="password"] {
    font-size: 4rem;
  }
  .featured {
    background: linear-gradient(
      to top,
      $color-bg-overlay 35%,
      rgba(0, 0, 0, 0.03) 37%
    );
    overflow: hidden;
    &__image {
      object-fit: contain;
      height: 65%;
    }
    &__title {
      font-size: 9rem;
      line-height: 9rem;
      margin: 2rem 0;
    }
    &__body {
      position: unset;
      width: 100%;
      padding: 0 2.5rem;
      max-height: 26%;
    }
    &__heading {
      position: absolute;
      bottom: 35%;
      left: 2.5rem;
      max-width: calc(100% - 27rem);
    }
    &__artist-title {
      font-size: 3.3rem;
      line-height: 3.2rem;
      position: absolute;
      top: 3.6rem;
      left: 3rem;
    }
    &__artist-name {
      font-weight: 600;
      font-size: 3.7rem;
      line-height: 3.7rem;
    }
    &__location {
      font-size: 3.7rem;
      position: absolute;
      top: 8rem;
      left: 3rem;
    }
    &__description {
      font-size: 2.3rem;
      line-height: 4.2rem;
      width: 100%;
    }
    &__action {
      bottom: 42%;
      width: 24rem;
      height: 24rem;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.3rem;
      right: 2.5rem;
    }
    &__link-to-events {
      &:link,
      &:visited,
      &:hover,
      &:active {
        font-style: normal;
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 2.7rem;
        bottom: 4%;
      }
    }
  }
  .stepper {
    width: 100%;
    padding: 2rem 2rem;
    height: unset;
    &::after {
      content: none;
    }
    &__step {
      width: unset;
      height: 1.2rem;
      flex: 1;
      border-radius: 5px;
      border: none;
      background-color: $color-grey-2;
      &--active,
      &--completed {
        background-color: $color-primary;
      }
      span {
        display: none;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    &__step-label {
      display: none;
    }
    &__step-bar {
      display: none;
    }
  }
  .event-page {
    height: calc(100vh - 31rem);
    height: calc((var(--vh, 1vh) * 100) - 31rem);
    &--full-height {
      height: calc(100vh - 11rem);
      height: calc((var(--vh, 1vh) * 100) - 11rem);
    }
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    &__body {
      flex-direction: column;
      flex: 1;
      display: flex;
      margin-bottom: 0;
      overflow-y: auto;
      padding: 0;
      width: 95%;
    }
    &--event-details &__event-day-details {
      color: $color-text-secondary;
    }
    &--event-details &__description {
      background-color: $color-bg-overlay;
      overflow-y: auto;
      &--details-page {
        position: relative;
        border-radius: 0;
        width: 100%;
        top: unset;
        right: unset;
        bottom: unset;
        padding: 5rem 4rem 0 4rem;
      }
    }
    &--event-details &__description,
    &--event-details &__info {
      flex: 1;
    }
    &--event-details &__info {
      background: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0.03) 6%);
    }
    &--event-details ~ .cta-popup {
      background-color: $color-bg-overlay;
      .cta-popup__timer {
        .countdown {
          color: $color-text-tertiary;
        }
      }
    }
    &__info {
      border: none;
      padding: 0;
      max-height: 70rem;
      width: 100%;
    }
    &__description {
      margin-right: 0;
      height: unset;
      overflow-y: unset;
      width: 100%;
      box-shadow: none;
      border-radius: 0;
    }
    &__description--padded {
      padding-top: 2rem;
    }
    &__event-image-title {
      font-size: 5.6rem;
      line-height: 5.6rem;
    }
    &__event-image-text {
      bottom: 4rem;
      left: 4rem;
      right: 4rem;
      width: unset;
    }
    &__event-image-date,
    &__event-image-location {
      font-weight: 500;
      font-size: 3.3rem;
      line-height: 3.3rem;
    }
    &__event-image-btns {
      &--desktop {
        display: none;
      }
      &--mobile {
        display: flex;
        padding: 0 4rem;
        background-color: $color-bg-overlay;
      }
    }
    &__title {
      font-weight: normal;
      font-size: 4.2rem;
      line-height: 4.2rem;
    }
    &--event-details &__title {
      color: $color-text-secondary;
    }
    &__paragraph {
      color: $color-grey-5;
      font-size: 3.25rem;
      line-height: 4.85rem;
      margin-bottom: 0;
    }
    &__event-image {
      position: relative;
      z-index: -1;
    }
    &__ticket-state {
      font-size: 2.7rem;
    }
    &__ticket-max-warning {
      font-size: 3.2rem;
      line-height: 3.8rem;
      margin-right: 1rem;
    }
    &__ticket-clear {
      font-size: 2.8rem;
      line-height: 2.8rem;
      height: 4rem;
    }
    &__ticket-clear-icon {
      width: 5rem;
      height: 4rem;
    }
    &__ticket-row {
      align-items: center;
    }
    &__ticket-name {
      flex: 2;
      font-size: 2.7rem;
      line-height: 2.7rem;
    }
    &__ticket-color {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
    }
    &__ticket-counter {
      flex: 1;
      justify-content: center;
      &--with-state {
        justify-content: flex-end;
        & ~ .event-page__ticket-dropdown-icon-wrapper {
          flex: 0;
        }
      }
    }
    &__ticket-name-wrapper {
      flex: 2;
    }
    &__ticket-dropdown-icon {
      width: 3.5rem;
      height: 2.5rem;
    }
    &__ticket-description {
      font-size: 2rem;
      line-height: 2.2rem;
    }
    &__ticket-price {
      justify-content: flex-start;
      font-size: 2.7rem;
      line-height: 2.7rem;
      margin-bottom: 2rem;
    }
    &__title--ticket-selector {
      font-weight: bold;
    }
    &__ticket-counter-icon {
      width: 4.5rem;
      height: 4.5rem;
    }
    &__ticket-count {
      font-size: 4.8rem;
      line-height: 4.8rem;
      margin: 0 2rem;
    }
    &__guests {
      width: 100%;
      height: unset;
      flex: 1;
    }
    &__region-select {
      &--selector {
        width: 20rem;
        font-size: 2rem;
      }
    }
  }
  .guests {
    width: calc(100% - 13rem);
    &__delete {
      font-size: 2rem;
      right: -14rem;
    }
    &__title {
      font-size: 2.6rem;
      line-height: 2.6rem;
      font-weight: 500;
    }
    &__collapsed-row {
      .guests__title {
        width: 14rem;
      }
    }
    &__status-icon {
      width: 3.2rem;
    }
    &__name {
      font-size: 2.6rem;
      line-height: 2.6rem;
      justify-content: flex-start;
    }
    &__ticket {
      font-size: 2.6rem;
      line-height: 2.6rem;
    }
    &__expand-icon {
      width: 3.2rem;
    }
    &__add-btn {
      font-size: 3.7rem;
      line-height: 3.7rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .registration-form__input {
      height: 8rem;
      font-size: calc(max(3rem, 16px));
      line-height: calc(max(3rem, 16px));
      border: 1px solid black;
    }
    .registration-form__input-group {
      margin-bottom: 5rem;
    }
    .registration-form__input-box {
      font-size: calc(max(3rem, 16px));
      height: 8rem;
    }
    .registration-form__input-icon {
      width: 4.2rem;
    }
    .PhoneInput {
      height: 100%;
      border: 1px solid black;
      &--error {
        border: 1px solid $danger;
      }
    }
    .PhoneInputInput {
      height: 100%;
      background-color: unset;
      font-size: calc(max(3rem, 16px));
    }
  }
  .labeled-btn {
    &__icon {
      width: 6.5rem;
      height: 6.5rem;
    }
    &__text {
      font-size: 2.8rem;
      line-height: 2.8rem;
      font-weight: 400;
    }
  }
  .share-list {
    bottom: 100%;
    right: 0;
    top: unset;
    padding: 3rem;
    width: 31rem;
    transform: translateY(0);
    &__close-icon {
      width: 2rem;
      height: 2rem;
      top: 1rem;
      right: 1rem;
    }
  }
  .cta-popup {
    height: 20rem;
    padding: 0 2.5rem;
    color: $color-text-primary;
    &__title {
      display: none;
    }
    &__btn-box {
      flex: 1;
      height: 13rem;
      .btn--primary {
        box-shadow: 1px 1px 11px 3px rgba($color-primary, 0.5);
      }
    }
    &__btn--disabled {
      box-shadow: none !important;
    }
    &__btn {
      border-radius: 2.8rem;
      font-size: 4rem;
      line-height: 4rem;
      width: 100%;
      height: 100%;
      .btn-line {
        height: 6.5rem;
      }
    }
    &__text-area {
      flex: unset;
    }
    &__warning {
      font-size: 3rem;
      line-height: 3rem;
    }
    &__display-message {
      font-size: 3rem;
      line-height: 3rem;
    }
    &--warning {
      height: unset;
      padding: 2.5rem;
      .cta-popup__error-btn {
        font-size: 3rem;
        line-height: 3rem;
        width: 15rem;
        height: 10rem;
      }
      .cta-popup__btn-box {
        height: 10rem;
      }
    }
    &--hidden {
      display: none;
    }
    &__timer {
      flex: unset;
      width: 20rem;
      margin: 0 2rem;
      .countdown {
        color: $color-tertiary;
      }
    }
    &__arrow-icon {
      margin-left: 0;
      width: 8.4rem;
      height: 6.4rem;
    }
  }
  .checkout {
    &__payment {
      border: none;
      padding-bottom: 0;
      overflow-y: auto;
      width: 100%;
      height: unset;
      flex: 1;
    }
    &__details {
      position: relative;
      margin-right: 0;
      height: unset;
      max-height: 60%;
      height: unset;
      margin-top: 0;
      margin-right: 0;
      &--hidden {
        display: none;
      }
      &--collapsed {
        .event-summary {
          display: none;
        }
        .checkout__grand-total {
          margin-top: 1rem !important;
        }
        .checkout__receipt {
          margin-top: 0;
        }
        .checkout__toggle {
          top: 0rem;
        }
      }
      width: 100%;
      .receipt-table {
        tbody tr:last-child td {
          border-bottom: 1px solid $color-text-primary;
        }
      }
    }
    &__details,
    &__payment {
      padding: 3rem 4rem;
    }
    &__subtitle {
      font-size: 3.7rem;
      line-height: 3.7rem;
      display: none;
    }
    &__paragraph {
      font-size: 2.8rem;
      line-height: 2.8rem;
      display: none;
    }
    &__title {
      font-size: 3.25rem;
      line-height: 3.25rem;
    }
    &__subparagraph {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
    &__receipt {
      .checkout__grand-total {
        display: flex;
        margin-top: 4rem;
        &-title,
        &-value {
          font-weight: normal;
        }
      }
    }
    &__receipt-toggle {
      font-size: 2.8rem;
      line-height: 2.8rem;
      height: 7rem;
      margin: 2rem 0 0 0;
    }
    &__receipt-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
    &__grand-total {
      display: none;
    }
    &__grand-total-title,
    &__grand-total-value {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
    &__grand-total-subparagraph {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
    &__details-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6rem;
      padding-top: 3rem;
    }
    &__mobile-collapse-bar {
      display: block;
      width: 14rem;
      height: 1.4rem;
    }
    &__edit-addons-icon,
    &__edit-guests-icon {
      width: 3.6rem;
      height: 3.6rem;
    }
    &__edit-addons,
    &__edit-guests {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
    &__order-actions {
      margin-bottom: 1rem;
      justify-content: flex-start;
    }
    &__cancel-order {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
    &__cancel-order-icon {
      width: 2rem;
      height: 2rem;
    }
    &__toggle {
      display: flex;
      justify-content: flex-end;
      position: sticky;
      top: 0rem;
      z-index: 3;
    }
    &__toggle-icon {
      transition: transform 0.3s;
      &--collapsed {
        transform: rotateX(180deg);
      }
      path {
        fill: $color-text-primary;
      }
    }
  }
  .terms {
    &__title {
      font-size: 2.6rem;
      line-height: 3.5rem;
      &::after {
        font-size: 3.6rem;
      }
    }
    &__body {
      font-size: 2.6rem;
      line-height: 2.9rem;
      max-height: 18rem;
    }
    &__btn {
      font-size: 2.6rem !important;
      line-height: 2.5rem !important;
    }
  }
  .addon {
    &__name {
      font-size: 2.3rem;
      line-height: 4rem;
      font-weight: bold;
    }
    &__price {
      font-size: 2.3rem;
      line-height: 2.3rem;
    }
    &__description {
      font-size: 2.6rem;
      line-height: 2.6rem;
      font-weight: normal;
    }
    &__counter {
      font-size: 3.2rem;
      line-height: 3.2rem;
      justify-content: flex-end;
      margin-right: 2rem;
    }
    &__counter-icon-wrapper {
      width: 3.5rem;
      height: 3.5rem;
      margin: 0 2rem;
    }
    &__dropdown-icon {
      width: 3.25rem;
      height: 3.25rem;
    }
  }
  .payment-method {
    &__name {
      font-size: 2.3rem;
      line-height: 2.3rem;
      font-weight: bold;
      /* .tooltip {
        &__icon {
          width: 2.8rem;
          height: 2.8rem;
        }
        &__text-box {
          bottom: unset;
          top: calc(100% + 2rem);
          &::after {
            bottom: unset;
            top: -1rem;
          }
        }
      } */
    }
    &__description {
      font-size: 2rem;
      line-height: 2rem;
    }
    &__icon {
      width: 4.65rem;
      &--image {
        width: 5rem;
        &--large {
          width: 9rem;
        }
      }
    }
  }
  .receipt-table {
    th,
    td,
    &__subtotal-tr &__subtotal-text {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
  }
  .countdown {
    &__time,
    &__expired,
    &__separator {
      font-size: 4.4rem;
      line-height: 4.4rem;
    }
    &__label {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
  .custom-checkbox {
    padding-left: 5rem;
    .checkmark {
      width: 24px;
      height: 24px;
      &:after {
        width: 6px;
        height: 12px;
        border-width: 0 2px 2px 0;
      }
    }
  }
  .custom-radio {
    padding-left: 5rem;
    .checkmark {
      width: 24px;
      height: 24px;
      &:after {
        width: 16px;
        height: 16px;
      }
    }
  }
  .navbar {
    &__link {
      &:link,
      &:visited,
      &:hover,
      &:active {
        font-size: 2.6rem;
        line-height: 2.6rem;
      }
      &--logged-in {
        font-size: 2rem !important;
        line-height: 2rem !important;
      }

    }
    &__registration-btn {
      border-radius: 2rem;
      font-size: 2.6rem;
      line-height: 2.6rem;
      padding: 1.5rem;
    }
    &__logout-btn {
      font-size: 4.2rem;
      line-height: 4.2rem;
    }
    &__welcome-message {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  .popup {
    width: 100%;
    height: 100%;
    border: none;
    z-index: 51;
    border-radius: 0px;
    transform: none;
    top: unset;
    bottom: 0;
    left: 0;
    &__close {
      width: 3rem;
      height: 3rem;
    }
    &__body {
      height: 100%;
      &--registration {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .registration-form {
    width: 100%;
    padding-top: 15rem;
    &--register,
    &--login {
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    &__title {
      font-size: 9.2rem;
      line-height: 9.2rem;
      margin-bottom: 6rem;
    }
    &__input-group {
      margin-bottom: 6rem;
      &--half {
        width: 100%;
      }
      &--double {
        display: unset;
        .registration-form__input-group:not(:first-child) {
          margin-left: 0;
        }
      }
    }
    &__input {
      background-color: $color-grey-6;
      border: none;
      padding: 0 6.6rem 0 2.5rem;
    }
    &__input-error-message {
      bottom: -5rem;
    }
    &__select-input {
      font-size: 4rem;
    }
    &__input-box {
      height: 8rem;
    }
    &__label {
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
    &__label::after {
      font-size: 5.5rem;
    }
    &__input-icon {
      width: 5.6rem;
      right: 2rem;
    }
    &__btn {
      border-radius: 2.8rem;
      width: 100%;
      height: 13rem;
      font-size: 4rem;
      line-height: 3rem;
      padding: 5rem;
    }
    &__redirect {
      font-size: 2.6rem;
      line-height: 2.6rem;
      margin-bottom: 5rem;
      margin-top: 5rem;
    }
    &--timed {
      padding-top: 5rem;
    }
    &__lock-paragraph {
      font-size: 3.4rem;
      line-height: 4.2rem;
    }
    &__lock-warning {
      font-size: 3.4rem;
      line-height: 4.2rem;
    }
    &__new-user-message {
      font-size: 3.4rem;
      line-height: 4.2rem;
      width: 100%;
    }
  }
  .registration-header {
    padding: 4rem 0;
    &__tickets {
      font-size: 4rem;
      line-height: 4rem;
    }
    &__timer {
      width: 24rem;
      height: 24rem;
    }
    &__title {
      font-size: 3.6rem;
      line-height: 3.6rem;
    }
  }
  .PhoneInput {
    border: none;
    border-radius: 2px;
    background-color: $color-grey-6;
    width: 100%;
    padding-left: 2rem;
    &--error {
      border: none;
    }
  }
  .PhoneInputInput {
    height: 8rem;
    font-size: 4rem;
    background-color: $color-grey-6;
    border: none;
    padding: 0 6.6rem 0 2.5rem;
  }
  .PhoneInputCountrySelect {
    width: 10rem;
  }
  .PhoneInputCountryIcon {
    width: 3rem !important;
    height: 3rem !important;
  }
  .error-message {
    font-size: 2.6rem;
    line-height: 4.6rem;
    font-weight: 500;
  }
  .success-display {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__tickets {
      font-size: 3.8rem;
      line-height: 3.8rem;
    }
    &__event {
      font-size: 3.8rem;
      line-height: 3.8rem;
    }
    &__icon-check {
      width: 24rem;
      height: 24rem;
      margin: 6rem 0;
    }
    &__message {
      font-size: 4rem;
      line-height: 4rem;
    }
    &__timer-wrapper {
      .countdown {
        &__time,
        &__separator,
        &__expired {
          font-size: 5rem;
          line-height: 5rem;
        }
      }
    }
  }
  .payment-iframe {
    height: 100%;
  }
  .expiry-popup {
    &__body {
      padding: 3rem;
    }
    &__action-message {
      font-weight: bold;
      font-size: 3.25rem;
      line-height: 3.77rem;
      text-transform: uppercase;
      margin-bottom: auto;
    }
    &__message {
      font-size: 3.25rem;
      line-height: 3.77rem;
      text-transform: uppercase;
      margin: 7rem 0;
    }
    &__btn {
      border-radius: 2rem;
      width: 100%;
      height: 9.8rem;
      font-size: 2.8rem;
      line-height: 2.8rem;
      margin-bottom: 4rem;
    }
    &__icon {
      display: block;
      height: 8.3rem;
      margin-top: auto;
    }
  }
  .order-card {
    &__event-title,
    &__summary-price {
      font-weight: 500;
    }
    &__event-detail,
    &__summary-date {
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
    &__order-summary {
      margin-right: 2rem;
    }
  }
  .myorders {
    padding: 2rem;
  }
  .payment-confirmation {
    flex-direction: unset;
    display: block;
    overflow-y: auto;
    height: calc(100vh - 11rem);
    height: calc((var(--vh, 1vh) * 100) - 11rem);
    &--with-action-bar {
      height: calc(100vh - 31rem);
      height: calc((var(--vh, 1vh) * 100) - 31rem);
      .payment-confirmation__event,
      .payment-confirmation__details {
        height: 50%;
      }
    }
    &__event-image-text {
      bottom: 4rem;
    }
    &__event {
      flex: unset;
      height: 50%;
      margin: 0;
    }
    &__details {
      margin-right: 0;
      overflow-y: unset;
      height: 50%;
      margin: 0;
    }
    &__payment-method-status {
      font-size: 2.6rem;
      line-height: 2.5rem;
    }
    &__payment-method-icon {
      width: 4rem;
    }
    &__reference-title {
      font-size: 2.6rem;
      line-height: 2.5rem;
    }
    &__reference-number {
      font-size: 3.5rem;
      line-height: 3.4rem;
    }
    &__payment-method-image {
      width: 8rem;
    }
    &__success-icon {
      width: 4rem;
      height: 4rem;
      margin-right: 1.5rem;
    }
    &__error-icon {
      width: 4rem;
      height: 4rem;
      margin-right: 1.5rem;
    }
    &__paragraph {
      font-size: 3.4rem;
    }
    &__download-icon {
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 1rem;
    }
    &__download-btn {
      font-size: 3rem;
      line-height: 3rem;
      font-weight: 600;
      padding: 2rem;
      border-radius: 2rem;
      margin-top: 3rem;
    }
    &__title {
      font-size: 4rem;
      line-height: 4rem;
      flex-direction: column;
      align-items: flex-start;
    }
    &__kiosk-dialog-status {
      font-size: 2.9rem;
      line-height: 2.9rem;
    }
    &__kiosk-description {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
    &__share-and-directions {
      height: 15rem;
    }
    .checkout__grand-total-title,
    .checkout__grand-total-value {
      font-weight: 400;
    }
    &__fail-paragraph {
      font-size: 2.6rem;
    }
    &__cancel-order {
      font-size: 3rem;
      line-height: 3rem;
    }
    &__cancel-order-icon {
      width: 2rem;
      height: 2rem;
    }
    &__btn-container {
      justify-content: center;
    }
    &__btn {
      width: 80%;
      border-radius: 2rem;
      font-size: 3.4rem;
      line-height: 3.4rem;
      padding: 3rem;
    }
    &__upgrade-dialog-title {
      font-size: 3.5rem;
      line-height: 3.4rem;
    }
    &__upgrade-dialog-description {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }
  .tooltip {
    left: calc(50% - 12rem);
    &__icon {
      width: 4.4rem;
      height: 4.4rem;
    }
    &__text-box {
      bottom: 6rem;
      width: 28rem;
      &::after {
        width: 2.4rem;
        height: 2.4rem;
        bottom: -1rem;
      }
    }
    &__title {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
    &__message {
      font-size: 2.2rem;
      line-height: 2.6rem;
    }
    &__close-icon {
      font-size: 2rem;
    }
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
  }
  .event-card {
    padding: 5rem 0;
    &__image-container {
      width: 29.65rem;
      height: 29.65rem;
    }
    &__cta {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
    }
    &__btn {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      border-radius: 0;
      width: 100%;
      height: 100%;
      font-weight: bold;
      font-size: 4.15rem;
      line-height: 4.15rem;
      z-index: 2;
    }
    &__see-more {
      &:active,
      &:visited,
      &:link,
      &:hover {
        display: none;
      }
      &--mobile,
      &--mobile:active,
      &--mobile:visited,
      &--mobile:link,
      &--mobile:hover {
        display: block;
        font: normal normal 700 1.9rem/1.9rem $font-primary;
        z-index: 3;
      }
    }
    &__text {
      margin-right: 0;
    }
    &__artist {
      font-size: 1.85rem;
      line-height: 1.85rem;
    }
    &__event-name {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }
    &__description {
      font-size: 2.32rem;
      line-height: 3.45rem;
      font-weight: normal;
      max-height: 14rem;
    }
    &__info {
      margin-top: auto;
      margin-bottom: 1.5rem;
      font-size: 2.32rem;
      line-height: 2.32rem;
    }
    &__share-area {
      z-index: 3;
    }
    &__share-icon {
      width: 3.9rem;
      height: 4.2rem;
    }
    .share-list {
      bottom: unset;
      top: 100%;
      transform: translateY(10%);
      z-index: 3;
    }
  }
  .event-summary {
    position: relative;
    background-color: transparent;
    box-shadow: none;
    &__body {
      flex: 1;
      padding-right: 12rem;
      justify-content: flex-start;
    }
    &__image-container {
      flex: 0 0 15rem;
      //height: 15rem;
    }
    &__artist,
    &__event-name {
      font-family: $font-primary;
      font-style: normal;
      font-weight: normal;
      font-size: 3.25rem;
      line-height: 3.25rem;
    }
    &__artist {
      margin-bottom: 1.2rem;
    }
    &__event-info {
      font-size: 2.6rem;
      line-height: 2.6rem;
      margin-top: auto;
    }
    &__action:not(:last-child) {
      margin-right: 1rem;
    }
    &__event-location {
      display: block;
      margin-top: 1rem;
    }
    &__action-area {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
    }
    &__action,
    &__action:link,
    &__action:visited,
    &__action:hover,
    &__action:active {
      font-size: 2.6rem;
      line-height: 2.6rem;
      & span {
        display: none;
      }
    }
    &__action-icon {
      flex: 0 0 4.6rem;
      height: 4.6rem;
    }
  }
  .event-info-popup {
    padding: 10rem 0 0 0;
    border-top-right-radius: 5rem;
    border-top-left-radius: 5rem;
    background-color: $color-bg;
    height: 95%;
    .popup__close {
      background-color: #38383a;
      padding: 1rem;
      box-sizing: content-box;
      border-radius: 50%;
      stroke: $color-text-secondary;
      right: 2.5rem;
      top: 2.5rem;
      path {
        fill: $color-text-secondary;
      }
    }
    .event-page {
      height: 100%;
      padding-bottom: 2rem;
      &__body {
        height: 100%;
      }
    }
  }
  .mobile-header {
    padding: 2rem 3rem;
    display: block;
    color: $color-primary;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;
    }
    &__title-text {
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 500;
      font-size: 6.95rem;
      line-height: 6.95rem;
    }
    &__subtitle {
      font-family: $font-primary;
      font-style: normal;
      font-weight: normal;
      font-size: 3.45rem;
      line-height: 3.45rem;
      color: $color-secondary;
    }
    &__cancel {
      width: 3.5rem;
      height: 3.5rem;
    }
    &__cancel-icon {
      width: 100%;
      height: 100%;
      path {
        fill: $color-text-primary;
      }
    }
  }

  .help-page {
    height: calc(98vh - #{$navbar-height});
    height: calc((var(--vh, 1vh) * 98) - #{$navbar-height});
    flex-direction: column;
    &__title-container {
      flex: unset;
    }
    &__title {
      position: relative;
      top: unset;
      left: unset;
      transform: none;
      background-image: linear-gradient(
        to right,
        $color-primary 0%,
        $color-grey-2 55%
      );
      font-size: 9.25rem;
      line-height: 9.25rem;
    }
    &__content {
      height: unset;
    }
  }
  .faq {
    &__icon {
      width: 4.9rem;
    }
    &__question {
      font-size: 3.7rem;
      line-height: 5.8rem;
    }
    &__answer {
      font-size: 3.7rem;
      line-height: 4.2rem;
    }
  }
  .forgot-password-page {
    width: 100%;
    &__form {
      width: 100%;
      padding: 0 5rem;
    }
    &__paragraph {
      font-size: 3rem;
    }
  }
  .reset-password-page {
    margin-left: 3rem;
    margin-right: 3rem;
    padding: 5rem 0rem;
    width: calc(100% - 6rem);
    margin-top: 20rem;
  }
  .presales-page {
    flex-direction: column;
    padding: 0;
    justify-content: unset;
    height: calc(100vh - #{$navbar-height} - 20rem);
    height: calc((var(--vh, 1vh) * 100) - #{$navbar-height} - 20rem);
    &__success,
    &__error {
      font-size: 5.6rem;
    }
    &__guests-area,
    &__ticket-selector {
      width: 100%;
      flex: 1;
      margin-left: 0;
      padding: 0 4rem;
    }
    &__ticket-selector {
      margin-top: 5rem;
      .event-page__title {
        display: block;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 6.95rem;
        line-height: 6.95rem;
      }
    }
    .checkout__subtitle {
      display: block;
    }
    .guests {
      width: calc(100% - 14rem);
    }
    .page-title {
      font-size: 6rem;
      line-height: 6rem;
    }
    .guests__add-btn {
      margin-left: 0;
    }
    &__description {
      font-size: 2.4rem;
    }
  }
  .profile {
    flex-direction: column;
    width: 100%;
    height: unset;
    margin-top: 8rem;
    &__side-bar {
      margin-right: 0;
      width: 100%;
      flex: unset;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &__body {
      width: 100%;
      flex: unset;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      padding-top: 8rem;
    }
    &__user-name {
      font-size: 4rem;
      line-height: 4rem;
    }
    &__picture-container {
      width: 25rem;
      height: 25rem;
    }
    &__tab-item {
      padding: 2rem;
    }
    &__tab-item-label {
      font-size: 3.8rem;
      line-height: 3.8rem;
    }
    &__tab-item-icon-container {
      width: 4.6rem;
      height: 4.6rem;
    }
    &__form-btn {
      padding: 2.2rem 3.5rem;
      font-size: 3.8rem;
      line-height: 3.8rem;
      border-radius: 2.4rem;
      flex: 1;
      height: 11rem;
    }
    &__form-message {
      font-size: 2.8rem !important;
      line-height: 2.8rem !important;
      margin-right: 1rem;
    }
  }
  .seat-details {
    &__seats-delete-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
    &__seats-table {
      td {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }
    &__button {
      padding: 2rem 2.5rem;
      border: 1px solid #eb7341;
      border-radius: 12px;
      font-size: 3.4rem;
      line-height: 3.4rem;
    }
    &__button-count {
      width: 6rem;
      height: 6rem;
    }
  }
  .payment-selector {
    &__header {
      font-size: 4rem;
      text-align: center;
      padding: 4rem 0;
      margin-bottom: 40rem;
    }
    &__btn {
      border-radius: 2rem;
      width: 100%;
      height: 9.8rem;
      font-size: 2.8rem;
      line-height: 2.8rem;
      margin-bottom: 4rem;
    }
    &__body {
      .payment-methods-list {
        &__custom-input-description {
          font-size: 2rem;
          margin-bottom: 2rem;
          align-self: center;
        }
        &__amount-section {
          flex-direction: column;
          .payment-method__body {
            margin-bottom: 4rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 48em) {
  html {
    font-size: 6px;
  }
  .custom-checkbox {
    padding-left: 5rem;
    .checkmark {
      width: 18px;
      height: 18px;
      &:after {
        width: 5px;
        height: 10px;
      }
    }
  }
  .custom-radio {
    padding-left: 5rem;
    .checkmark {
      width: 18px;
      height: 18px;
      &:after {
        width: 12px;
        height: 12px;
      }
    }
  }
  .empty-page {
    padding: 10rem;
    &__message {
      font-size: 4rem;
      line-height: 4.4rem;
    }
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 4.8px;
  }
  .custom-checkbox {
    padding-left: 5rem;
    .checkmark {
      width: 16px;
      height: 16px;
      &:after {
        width: 4px;
        height: 8px;
        border-width: 0 1px 1px 0;
      }
    }
  }
  .custom-radio {
    padding-left: 5rem;
    .checkmark {
      width: 16px;
      height: 16px;
      &:after {
        width: 10px;
        height: 10px;
      }
    }
  }
}
@media only screen and (max-width: 25em) {
  html {
    font-size: 4.32px;
  }
  .cta-popup {
    &__btn {
      .btn-line {
        border-right: 1px solid #fff;
      }
    }
  }
  .custom-checkbox {
    padding-left: 5rem;
    .checkmark {
      width: 14px;
      height: 14px;
      &:after {
        width: 4px;
        height: 8px;
      }
    }
  }
  .custom-radio {
    padding-left: 5rem;
    .checkmark {
      width: 14px;
      height: 14px;
      &:after {
        width: 8px;
        height: 8px;
      }
    }
  }
  .event-page {
    &__region-select {
      &--selector {
        width: 25rem;
        font-size: 3rem;
      }
    }
  }
}
@media only screen and (max-width: 22em) {
  html {
    font-size: 3.52px;
  }
  .custom-checkbox {
    .checkmark {
      width: 12px;
      height: 12px;
      &:after {
        width: 3px;
        height: 7px;
      }
    }
  }
  .custom-radio {
    .checkmark {
      width: 12px;
      height: 12px;
    }
  }
}
