$color-bg: #4d9faf;
$color-bg-secondary: #4d9faf;
$color-bg-overlay: black;
$color-text-primary: #FFFFFF;
$color-text-secondary: black;
$color-text-tertiary: #FFFFFF;
$color-primary: #FFFFFF;
$color-secondary: #aeaeae;
$color-tertiary: #ffffff;
$color-disabled: #4d4d4d;
